<!--
 * @Description: 人行道停车运营管理 停车场管理 车位管理 truckSpaceManage
 * @Author: Li bin
 * @Github: https://github.com/hzylyh
 * @Date: 2021-03-17 09:00:11
 * @LastEditors: zhoucheng
-->
<template>
  <div class="mainbody">
    <el-scrollbar style="height:100%">
      <el-row class="topSelectTwoLine">
        <el-form :inline="true"
                 :model="searchForm">
          <el-row class="topFirstLine">
            <el-col :span="6">
              <el-form-item label-width="90px"
                            label="停车场名称">
                <el-select class="search-input"
                           clearable
                           v-model="searchForm.parkId"
                           placeholder="全部">
                  <el-option v-for="(item, index) in parkNameList"
                             :key="index"
                             :label="item.name"
                             :value="item.code">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="topButton">
            <el-button type="primary"
                       @click="handleClickSearch">查 询</el-button>
            <el-button type="info"
                       style="margin-left:17px"
                       @click="resetForm">重 置</el-button>
          </el-row>
        </el-form>
      </el-row>
      <div class="tableRow">
        <div class="tableTitle">车位管理</div>
        <!-- 表格 -->
        <el-row style="margin-bottom:30px">
          <el-table ref="parkingSpaceTable"
                    style="width: 100%"
                    :row-class-name="tableRowClassName"
                    row-key="parkId"
                    border
                    tooltip-effect="dark"
                    :data="
              parkingSpaceTable.slice(
                (currentPage - 1) * pageSize,
                currentPage * pageSize
              )
            ">
            <el-table-column width="50"
                             type="expand">
              <template slot-scope="props">
                <el-table :data="singleTable[props.row.parkId]"
                          :row-class-name="tableRowClassName"
                          style="width: 100%">
                  <el-table-column width="50"> </el-table-column>
                  <el-table-column prop="parkLayerName"
                                   label="停车场平面层名称"
                                   width="500">
                  </el-table-column>
                  <el-table-column prop="parkSpaceNum"
                                   label="车位数(个)"
                                   width="450">
                  </el-table-column>
                  <el-table-column prop="remark"
                                   label="描述"
                                   width="450">
                  </el-table-column>
                  <el-table-column label="操作">
                    <template slot-scope="scope">
                      <el-button type="text"
                                 @click="parkingSpaceDetails(scope.$index, scope.row)">
                        车位详情
                      </el-button>
                      <el-button type="text"
                                 @click="handleOrderUpdate(scope.$index, scope.row)">
                        修改
                      </el-button>
                      <el-button type="text"
                                 @click="handleOrderDelete(scope.$index, scope.row)">
                        删除
                      </el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </template>
            </el-table-column>
            <el-table-column label="停车场名称"
                             width="450">
              <template slot-scope="scope">
                <span>{{ scope.row.parkName }}</span>
              </template>
            </el-table-column>
            <el-table-column label="车位数(个)"
                             width="450">
              <template slot-scope="scope">
                <span>{{ scope.row.parkSpaceNum }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="operation"
                             label="操作">
              <template slot-scope="scope">
                <el-button type="text"
                           size="medium"
                           @click="addPlaneLayer(scope.$index, scope.row)">
                  添加平面层
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
        <!-- 分页 -->
        <el-row style="bottom: 20px"
                justify="space-between">
          <el-col :span="4"
                  class="pageLeft">
            共{{this.total}}条
          </el-col>
          <el-col :span="20">
            <el-pagination class="page"
                           layout="prev,sizes,pager,next,jumper"
                           background
                           @current-change="getList"
                           @size-change="sizeChange"
                           :current-page="pageNum"
                           :page-size="pageSize"
                           :page-sizes=[15,50,200]
                           :total="total">
            </el-pagination>
          </el-col>
        </el-row>
      </div>
    </el-scrollbar>

    <el-dialog :title="this.dialogTitle"
               append-to-body
               @close="popClose"
               :visible.sync="dialogVisible">
      <el-form :model="inputForm"
               :rules="rules"
               ref="inputForm"
               :inline="true"
               size="small"
               label-width="110px">
        <el-form-item label="平面层名称"
                      prop="parkLayerName">
          <el-input class="form-input"
                    v-model="inputForm.parkLayerName" />
        </el-form-item>
        <el-form-item label="车位数(个)"
                      prop="parkSpaceNum">
          <el-input class="form-input"
                    v-model="inputForm.parkSpaceNum" />
        </el-form-item>
        <el-form-item label="描述"
                      prop="remark">
          <el-input class="form-input"
                    v-model="inputForm.remark" />
        </el-form-item>
        <el-form-item>
          <el-row class="dt-button-box">
            <el-button type="success"
                       size="medium"
                       @click="handleSave('inputForm')">确 定</el-button>
          </el-row>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
// import axios from "axios"
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    //这里存放数据
    return {
      searchForm: { parkTypeCode: '2' },
      pageSize: 15, // 每页的数据条数
      pageNum: 1,
      total: 0, //数据总条数

      parkNameList: [], // 停车场列表
      parkIds: [], // 停车场id列表

      currentPage: 1, // 当前页码

      parkName: "",
      form: {
        search: "",
      },
      //弹出框表单
      inputForm: {},
      //弹出框属性
      dialogVisible: false,
      dialogType: 0, //弹出层类型
      dialogTitle: "", //弹出层标题
      //假数据
      parkingSpaceTable: [],
      singleTable: [],
      delId: [],
      rules: {
        parkLayerName: [{ required: true, message: "请输入平面层名称", trigger: "blur" },],
        parkSpaceNum: [{ required: true, message: "请输入车位数(个)", trigger: "blur" },],
        remark: [{ required: true, message: "请输入平层描述", trigger: "blur" },],
      },
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created () { },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.queryParkList()
    this.queryTableList();
  },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
  //方法集合
  methods: {
    //获取停车场
    queryTableList () {
      this.parkIds = [];
      this.searchForm["pageNum"] = this.pageNum
      this.searchForm["pageSize"] = this.pageSize
      this.$yardManagementApi.ParkController(this.searchForm).then((response) => {
        let data = response.resultEntity.list;
        this.total = response.resultEntity.total;
        this.parkingSpaceTable = data;
        data.forEach((item) => {
          this.parkIds.push(item.parkId);
        });
        this.getParkLayerController(); //获取停车层
      })
    },
    // 分页
    getList (change) {
      this.pageNum = change
      this.queryTableList()
    },
    sizeChange (change) {
      this.pageSize = change
      this.pageNum = 1
      this.queryTableList()
    },
    // 查询按钮
    handleClickSearch () {
      this.pageNum = 1
      this.queryTableList()
    },
    // 重置按钮
    resetForm () {
      this.searchForm = {}
      this.pageNum = 1
      this.queryTableList()
    },
    //停车场平面层查询
    getParkLayerController () {
      this.parkIds.forEach((item) => {
        let info = {
          parkId: item,
        };
        this.$yardManagementApi.ParkLayerController(info).then((response) => {
          this.singleTable[item] = response.resultEntity;
        });
      });
    },
    //新增
    insertParkLayerController () {
      let info = this.inputForm;
      this.$yardManagementApi.insertParkLayerController(info).then(() => {
        this.queryTableList();
        this.dialogVisible = false;
        window.location.reload()
      })
    },
    //修改
    updateParkLayerController () {
      let info = this.inputForm;
      this.$yardManagementApi.updateParkLayerController(info).then(() => {
        this.queryTableList();
        this.dialogVisible = false;
        window.location.reload()
      })
    },
    //删除
    deleteParkLayerController () {
      this.$confirm("确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$yardManagementApi.deleteParkLayerController(this.delId).then(() => {
          this.$message({ message: "删除成功", type: "success", });
          this.queryTableList();
          this.dialogVisible = false;
          window.location.reload()
        })
      })
    },
    //查看详情跳转
    parkingSpaceDetails (index, row) {
      let parkId = row.parkId;
      let parkLayerId = row.parkLayerId;
      this.$router.push({
        name: "parkingSpaceManagement",
        query: { parkId: parkId, parkLayerId: parkLayerId },
      });
    },
    //保存
    handleSave (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.dialogType == 0) {
            this.$message({ message: "新增成功", type: "success", });
            this.insertParkLayerController();
          } else if (this.dialogType == 1) {
            this.$message({ message: "修改成功", type: "success", });
            this.updateParkLayerController();
          }
        } else {
          return false;
        }
      });
    },
    //修改记录
    handleOrderUpdate (index, row) {
      this.dialogTitle = "修改本条记录";
      this.inputForm = this.$deepClone(row);
      this.dialogType = 1;
      this.dialogVisible = true;
    },
    // 删除记录
    handleOrderDelete (index, row) {
      this.delId = [{ parkLayerId: row.parkLayerId, parkId: row.parkId }];
      this.deleteParkLayerController();
    },
    //新增
    addPlaneLayer (index, row) {
      this.inputForm.parkId = row.parkId;
      this.dialogTitle = "添加平面层";
      this.dialogType = 0;
      this.dialogVisible = true;
    },
    // 获取停车场名称
    queryParkList () {
      let info = {
        columnName: ['park_id', 'park_name'], //有且只有两个 id放前面名称放后面
        tableName: 'tb_park',
        "whereStr": [
          {
            "colName": "park_type_code",
            "value": '2'
          }
        ]
      }
      this.$queryDict.queryDictWithAuth(info).then((response) => {
        this.parkNameList = response.resultEntity
      })
    },
    popClose () {
      this.inputForm = {};
      this.$refs.inputForm.resetFields();
    },
    //斑马线
    tableRowClassName ({ rowIndex }) {
      if (rowIndex % 2 === 0) {
        return "double-row";
      } else if (rowIndex % 2 === 1) {
        return "odd-row";
      }
      return "";
    },
  },
};
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
$topSelectHeight: 135px;
.mainbody {
  height: 100%;
  overflow-x: hidden;
  // 顶部查询条件
  .topSelectTwoLine {
    width: 100%;
    height: $topSelectHeight;
    @include background('blockBg');
    padding-top: 24.3px;
    padding-left: 21px;
    .topButton {
      display: flex;
      padding-left: 91px;
      margin-top: 17px;
    }
  }
  .tableRow {
    width: 98%;
    @include background('blockBg');
    margin-top: 16px;
    padding-left: 20.2px;
    padding-right: 20.2px;
    padding-top: 5px;
    .tableTitle {
      height: 32px;
      padding-top: 15px;
      .topButton {
        display: flex;
        padding-left: 91x;
        float: right;
      }
    }
    .tableContent {
      margin-top: 25px;
    }
  }

  //子表
  /deep/ .el-table__expanded-cell[class*='cell'] {
    padding: 0px 0px !important;
  }
  /deep/ .mainbody[data-v-6b05d2c1] .el-table__expanded-cell[class*='cell'] {
    padding: 0;
  }
  //子表颜色
  /deep/.el-table,
  .el-table__expanded-cell {
    background-color: rgb(10, 33, 67) !important;
  }
  //分页
  .page {
    float: right;
    color: #70aee8;
  }
  .pageLeft {
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: bold;
    @include font_color('paginationFontColor');
  }
}
//选中的按钮
/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: rgba(255, 255, 255, 0);
}
/deep/ .el-pager li {
  margin: 0rem !important;
  min-width: 1.63rem;
}
/deep/ li.el-icon.more.btn-quickprev.el-icon-more {
  background-color: #051835 !important;
  color: #70aee8 !important;
  border: 0.08rem solid #004474;
}
/deep/li.el-icon.more.btn-quicknext.el-icon-more {
  background-color: #051835 !important;
  color: #70aee8 !important;
  border: 0.08rem solid #004474;
}

.Pager {
  float: right;
}
//去除分页器顶部的边距
/deep/.page.el-pagination.is-background {
  margin-top: 0rem;
}

//弹出层
/deep/.el-dialog__header {
  //弹出框顶部
  background-image: linear-gradient(180deg, #001933 0%, #004480 100%);
  font-family: PingFangSC-Regular;
  font-size: 16px;
  color: #00ebfb;
}

/deep/ .el-table .el-table__expand-icon .el-icon-arrow-right:before {
  content: '\e6d9';
  border: 1px solid;
}
/deep/ .el-table .el-table__expand-icon--expanded {
  transform: rotate(0);
  .el-icon-arrow-right:before {
    content: '\e6d8';
    border: 1px solid;
    @include background('tableIconBg');
    @include border_color('tableIconBor');
    @include font_color('tableIconColor');
  }
}
</style>